/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { animated, useTransition, AnimatedValue } from 'react-spring'
import '@reach/dialog/styles.css'

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

const Modal = ({
  animationProps,
  children,
  showDialog,
  onDismiss
}: {
  showDialog: boolean
  animationProps?: AnimatedValue<{}>
  children: React.ReactNode
  onDismiss: () => void
}) => {
  const transitions = useTransition(showDialog, null, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: {
      tension: 280
    }
  })

  return (
    <React.Fragment>
      {transitions.map(
        ({ item, key, props: { opacity, y } }) =>
          item && (
            <AnimatedDialogOverlay
              key={key}
              style={{ opacity, zIndex: 200 }}
              onDismiss={onDismiss}
            >
              <AnimatedDialogContent
                sx={{
                  '&[data-reach-dialog-content]': {
                    borderRadius: 0,
                    p: 0,
                    m: [0, 'auto'],
                    background: 'none',
                    width: ['100%', '90%'],
                    maxWidth: 600,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    maxHeight: '100%',
                    pointerEvents: 'none'
                  }
                }}
                style={{
                  transform: y.interpolate(
                    (value: number) => `translate3d(0px, ${value}px, 0px)`
                  )
                }}
                aria-labelledby="title"
              >
                <div
                  sx={{
                    m: [0, 'auto'],
                    width: '100%',
                    pointerEvents: 'all',
                    height: ['100%', 'auto']
                  }}
                >
                  <div
                    sx={{
                      position: 'relative',
                      background: 'var(--main-color)',
                      m: [0, '1em 0'],
                      p: 5,
                      minHeight: ['100%', 0]
                    }}
                  >
                    {children}
                  </div>
                </div>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </React.Fragment>
  )
}

export default Modal
