/** @jsx jsx */
import { Box, Text, Grid, jsx } from 'theme-ui'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import ky from 'ky'

import { CloseButton, Button } from '@components/Button'
import Modal from '@components/Modal'

import ContributionButtons from '@components/forms/ContributionButtons'
import Input from '@components/forms/Input'

const initialValues = { Id: '', Name: '', Email: '' }

const schema = yup.object().shape({
  Id: yup.number().required(),
  Name: yup.string().required('Please enter a name'),
  Email: yup.string().email().required('Please enter your e-mail address'),
})

const DonateModal = ({
  showDialog,
  onDismiss,
}: {
  showDialog: boolean
  onDismiss: () => void
}) => (
  <Modal showDialog={showDialog} onDismiss={onDismiss}>
    <Grid>
      <Formik
        initialStatus={{ error: undefined }}
        onSubmit={async (values, { setStatus }) => {
          try {
            setStatus({ error: undefined })
            const data = await ky
              .post('https://rara-donate.herokuapp.com/api/donate', {
                searchParams: values,
              })
              .json()
            window.location.href = data.data.url
          } catch (e) {
            setStatus({ error: 'Could not contact server.' })
          }
        }}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({ values, status, isSubmitting }) => (
          <Form>
            <Grid>
              <ContributionButtons />
              {values.Id && (
                <Grid>
                  <Text sx={{ textAlign: 'center' }}>
                    Please enter these details to finalise your contribution:
                  </Text>
                  <fieldset sx={{ border: 'none', p: 0, m: 0 }}>
                    <Field
                      name="Name"
                      id="name"
                      placeholder="Name"
                      component={Input}
                    />
                    <Field
                      name="Email"
                      id="email"
                      type="email"
                      placeholder="E-mail address"
                      component={Input}
                    />
                    <Box mt={2}>
                      <ErrorMessage name="Name">
                        {(msg) => (
                          <Text sx={{ textAlign: 'center' }}>{msg}</Text>
                        )}
                      </ErrorMessage>
                      <ErrorMessage name="Email">
                        {(msg) => (
                          <Text sx={{ textAlign: 'center' }}>{msg}</Text>
                        )}
                      </ErrorMessage>
                      {status && status.error && (
                        <Text sx={{ textAlign: 'center' }}>{status.error}</Text>
                      )}
                    </Box>
                  </fieldset>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    sx={{
                      m: 0,
                      display: 'block',
                      width: '100%',
                      border: 'none',
                      color: 'var(--main-color)',
                      bg: 'black',
                      '&:hover': {
                        opacity: 0.5,
                      },
                    }}
                  >
                    Continue to payment
                  </Button>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      <CloseButton onClick={onDismiss} />
    </Grid>
  </Modal>
)

export default DonateModal
