/** @jsx jsx */
import { jsx } from 'theme-ui'

const MutedIcon = ({ muted, ...props }) => (
  <svg viewBox="0 0 42 42" {...props}>
    <circle cx={21} cy={21} r={21} fill="var(--main-color)" />
    {muted ? (
      <path d="M26.25 21a5.25 5.25 0 00-2.917-4.702v2.579l2.859 2.858c.035-.233.058-.478.058-.735zm2.917 0a7.983 7.983 0 01-.63 3.08l1.761 1.762A10.262 10.262 0 0031.5 21c0-4.993-3.488-9.17-8.167-10.232v2.404A8.173 8.173 0 0129.167 21zM11.982 10.5L10.5 11.982l5.518 5.518H10.5v7h4.667L21 30.333v-7.851l4.958 4.958a8.077 8.077 0 01-2.625 1.377v2.403a10.488 10.488 0 004.305-2.112l2.38 2.392 1.482-1.482-10.5-10.5-9.018-9.018zM21 11.667l-2.438 2.438L21 16.543v-4.876z" />
    ) : (
      <path d="M12.75 17.5v7h4.667l5.833 5.833V11.667L17.417 17.5z" />
    )}
  </svg>
)

export default MutedIcon
