/** @jsx jsx */
import React from 'react'
import { jsx, Text } from 'theme-ui'
import ReactPlayer from 'react-player'
import shallow from 'zustand/shallow'
import { useMediaQuery } from 'react-responsive'

import { useStore } from '@components/api'
import MutedIcon from '@components/MutedIcon'

const Stream = ({
  hideFooter,
  test
}: {
  hideFooter?: boolean
  test?: boolean
}) => {
  const [muted, setMuted] = React.useState(true)
  const [isInitialTap, setIsInitialTap] = React.useState(true)
  const isSmallScreen = useMediaQuery({
    query: '(max-device-width: 800px)'
  })
  const { streamURL, rerunStreamURL, testStreamURL, live, rerun } = useStore(
    ({ live, rerun, streamURL, rerunStreamURL, testStreamURL }) => ({
      live,
      rerun,
      streamURL,
      rerunStreamURL,
      testStreamURL
    }),
    shallow
  )
  const [hiddenControls, hideControls] = React.useState(false)
  const timer = React.useRef<NodeJS.Timeout>(null)
  const video = React.useRef<ReactPlayer>(null)
  const stream = test
    ? testStreamURL
    : rerun
    ? rerunStreamURL
    : live
    ? streamURL
    : undefined

  const handleTap = () => {
    if (isInitialTap) setIsInitialTap(false)
    clearTimeout(timer.current)
    if (!hiddenControls) {
      hideControls(true)
    } else {
      hideControls(false)
      timer.current = setTimeout(() => {
        hideControls(true)
      }, 5000)
    }
  }

  const unMute = () => {
    setMuted(false)
    try {
      video.current?.getInternalPlayer().play()
    } catch (e) {
      console.warn(e)
    }
    hideControls(true)
  }

  const toggleMuted = ev => {
    ev.stopPropagation()
    setMuted(m => !m)
    try {
      video.current?.getInternalPlayer().play()
    } catch (e) {
      console.warn(e)
    }
    handleTap()
  }

  const handleMouseMove = () => {
    if (hiddenControls) hideControls(false)
  }

  const handleMouseLeave = () => {
    if (!muted && !hiddenControls) {
      hideControls(true)
    }
  }

  const handleClick = () => {
    if (isInitialTap) setIsInitialTap(false)
    if (hiddenControls) {
      handleTap()
    } else {
      if (muted) {
        unMute()
      } else {
        handleTap()
      }
    }
  }

  return !stream || stream === '' ? (
    <div
      sx={{
        width: ['90vw', '60vw'],
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Text>Stream offline</Text>
    </div>
  ) : (
    <div
      sx={{
        width: ['90vw', '60vw'],
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        cursor: muted ? 'pointer' : 'default'
      }}
      role={muted ? 'button' : undefined}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onClick={isSmallScreen ? undefined : handleClick}
    >
      <div
        sx={{
          position: 'relative',
          paddingTop: '56.25%',
          bg: 'var(--main-color)',
          '.player': {
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.25)'
          }
        }}
      >
        <ReactPlayer
          ref={video}
          className="player"
          url={stream}
          playing
          controls={isSmallScreen}
          muted={!hideFooter && muted}
          // onReady={initializeContext}
          width="100%"
          height="100%"
          playsinline
        />

        {hideFooter
          ? null
          : (muted || !hiddenControls) &&
            (isSmallScreen && isInitialTap ? (
              <div
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                onClick={handleClick}
              >
                <MutedIcon
                  muted={muted}
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    height: '3em',
                    transition: 'opacity 0.3s ease',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  role="button"
                  onClick={toggleMuted}
                />
              </div>
            ) : (
              !isSmallScreen && (
                <MutedIcon
                  muted={muted}
                  sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    height: '3em',
                    transition: 'opacity 0.3s ease',
                    '&:hover': { cursor: 'pointer' }
                  }}
                  role="button"
                  onClick={toggleMuted}
                />
              )
            ))}
      </div>
    </div>
  )
}

export default Stream
