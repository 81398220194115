/** @jsx jsx */
import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Grid, jsx, Text, Box, Flex } from 'theme-ui'
import * as yup from 'yup'
import { ResizeObserver } from '@juggle/resize-observer'
import useMeasure from 'react-use-measure'
import { ellipsis } from 'polished'
import { useSpring } from 'react-spring'

import { useStore } from '@components/api'
import { sendMessage } from '@contexts/Socket'

import { NoBorderInput } from '@components/forms/Input'

const initialValues = { message: '', name: '' }

const schema = yup.object().shape({
  name: yup.string().max(15),
  message: yup.string().max(140),
})

const interpolateX = (x) =>
  x
    .interpolate({
      range: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      output: [0, -1, 2, -4, 4, -4, 4, -4, 2, -1, 0],
    })
    .interpolate((x) => `translateX(${x}px)`)

const ArrowButton = (props) => (
  <Flex
    as="button"
    sx={{
      appearance: 'none',
      fontSize: '1.2rem',
      fontFamily: 'inherit',
      gridArea: 'submit',
      alignItems: 'center',
      justifyContent: 'center',
      m: 0,
      border: 'none',
      minWidth: 0,
      p: 2,
      background: 'none',
      color: 'black',
      mb: '-4px',
      borderRadius: '1em',
      svg: {
        fill: 'black',
      },
      '&:hover': {
        opacity: 0.5,
        cursor: 'pointer',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 1px black',
      },
    }}
    {...props}
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.08 0.78L8.12 1.74L12.7 6.32H0.5V7.68H12.7L8.12 12.26L9.08 13.22L15.3 7L9.08 0.78Z" />
    </svg>
  </Flex>
)

const Chat = () => {
  const messages = useStore((s) => s.messages)
  const [shakeNameState, shakeName] = React.useState(false)
  const [shakeMessageState, shakeMessage] = React.useState(false)
  const { x: nameX } = useSpring({
    from: { x: 0 },
    x: shakeNameState ? 1 : 0,
    config: { duration: 600 },
  })
  const { x: inputX } = useSpring({
    from: { x: 0 },
    x: shakeMessageState ? 1 : 0,
    config: { duration: 600 },
  })
  const [hasName, setHasName] = React.useState<string | undefined>(undefined)
  const [showToast, setShowToast] = React.useState(false)
  const [hasScrolled, setHasScrolled] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const nameRef = React.useRef<HTMLInputElement>(null)
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const initialRender = React.useRef(true)
  const [measureRef, { height }] = useMeasure({
    polyfill: ResizeObserver,
  })
  const scrollMargin = 30

  const updateScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scroll({
        top: scrollRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
      setHasScrolled(false)
      setShowToast(false)
    }
  }

  React.useEffect(() => {
    if (scrollRef.current) {
      if (!hasScrolled) {
        updateScroll()
      } else {
        setShowToast(true)
      }
    }
  }, [messages])

  const handleScroll = (ev: React.UIEvent<HTMLDivElement>) => {
    if (!ev.target) return
    const isAtBottom =
      ev.target.scrollTop >
      ev.target.scrollHeight - ev.target.clientHeight - scrollMargin
    if (!hasScrolled && !isAtBottom) {
      setHasScrolled(true)
    } else if (hasScrolled && isAtBottom) {
      setShowToast(false)
      setHasScrolled(false)
    }
  }

  React.useEffect(() => {
    if (hasName && inputRef.current) {
      inputRef.current.focus()
    } else if (!initialRender.current && nameRef.current) {
      nameRef.current.focus()
    }
    initialRender.current = false
  }, [hasName])

  return (
    <Box sx={{ height: '100%', overflow: 'hidden' }}>
      <Grid
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          webkitOverflowScrolling: 'touch',
          maxHeight: ['55vh', 'none'],
        }}
        ref={scrollRef}
        onScroll={handleScroll}
      >
        <Grid
          sx={{
            p: 3,
            justifyContent: ['flex-start', 'flex-end'],
            alignItems: 'flex-end',
            alignContent: 'flex-end',
            gridRowGap: 2,
          }}
        >
          {messages.length === 0 && (
            <Text sx={{ display: ['block', 'none'] }}>No messages yet.</Text>
          )}
          {messages.map((message, i) => (
            <Grid
              key={`message-${i}`}
              sx={{
                gridTemplateRows: 'max-content max-content',
                gridRowGap: 0,
                bg: message.isDonation ? 'black' : 'transparent',
                color: message.isDonation ? 'var(--main-color)' : 'black',
                px: message.isDonation ? 2 : 0,
                py: message.isDonation ? 1 : 0,
                textAlign: ['left', 'right'],
                justifySelf: ['flex-start', 'flex-end'],
              }}
            >
              <Text sx={{ fontStyle: 'italic', ...ellipsis() }}>
                {message.name}
              </Text>
              <Text>{message.content}</Text>
            </Grid>
          ))}

          <Box sx={{ height: `calc(${height}px - 0.5em)` }} />
        </Grid>
      </Grid>
      <Formik
        onSubmit={(values, { setFieldValue, setSubmitting }) => {
          const message = values.message.trim()
          const name = values.name.trim()
          if (name === '') {
            setHasName(undefined)
            if (nameRef.current) {
              nameRef.current.focus()
              shakeName(!shakeNameState)
            }
            setSubmitting(false)
            return
          }
          if (hasName && message !== '') {
            sendMessage({ content: message, name })
            setSubmitting(false)
            updateScroll()
            setFieldValue('message', '')
          } else {
            setHasName(name)
            if (inputRef.current) {
              shakeMessage(!shakeMessageState)
            }
            setSubmitting(false)
          }
        }}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form
            sx={{
              position: 'absolute',
              bottom: [2, 2],
              right: [2, 2],
              left: [2, 'auto'],
              p: 2,
              pl: 10,
              width: ['calc(100% - 16px)', 'auto'],
              border: '1px solid black',
              bg: 'var(--main-color)',
              ml: [0, '-2.5em', 0],
            }}
            ref={measureRef}
          >
            <Grid
              sx={{
                gridTemplateAreas: `'name submit' 'message submit'`,
                gridTemplateColumns: '1fr max-content',
                alignItems: 'flex-end',
                gridRowGap: 1,
                gridColumnGap: 1,
              }}
            >
              <Field name="name">
                {(props) => (
                  <NoBorderInput
                    ref={nameRef}
                    {...props}
                    placeholder="Your name"
                    maxLength={15}
                    autoCorrect="off"
                    autoComplete="off"
                    spellCheck="false"
                    sx={{
                      fontStyle: hasName ? 'italic' : 'normal',
                      gridArea: 'name',
                      transform: 'translateX(0)',
                      '&:hover': { cursor: hasName ? 'pointer' : 'default' },
                    }}
                    style={{
                      transform: interpolateX(nameX),
                    }}
                    onClick={() => {
                      if (hasName) setHasName(undefined)
                    }}
                    onBlur={() => {
                      setFieldValue('name', values.name.trim())
                      setHasName(values.name.trim())
                    }}
                  />
                )}
              </Field>
              <Field name="message">
                {(props) => (
                  <NoBorderInput
                    ref={inputRef}
                    maxLength={140}
                    placeholder="Your message"
                    style={{ transform: interpolateX(inputX) }}
                    {...props}
                  />
                )}
              </Field>
              <ArrowButton type="submit" disabled={isSubmitting} />
            </Grid>
          </Form>
        )}
      </Formik>
      {showToast && (
        <Flex
          as="button"
          sx={{
            border: 'none',
            appearance: 'none',
            fontSize: '1rem',
            fontFamily: 'inherit',
            bg: 'black',
            color: 'var(--main-color)',
            px: 2,
            py: 1,
            position: 'absolute',
            bottom: `calc(${height}px + 1em)`,
            right: ['auto', 2],
            left: [2, 'auto'],
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={updateScroll}
        >
          New messages
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              ml: 2,
              fill: 'var(--main-color)',
              transform: 'rotate(90deg)',
            }}
          >
            <path d="M9.08 0.78L8.12 1.74L12.7 6.32H0.5V7.68H12.7L8.12 12.26L9.08 13.22L15.3 7L9.08 0.78Z" />
          </svg>
        </Flex>
      )}
    </Box>
  )
}

export default Chat
