/** @jsx jsx */
import { Grid, Flex, jsx } from 'theme-ui'

import { people } from '@utils/people'
import { useStore } from '@components/api'

const Emojis = () => {
  const clients = useStore(s => s.clients)

  return clients.map((client, i) =>
    people[client.emoji] ? (
      <span key={`p-${i}`} role="img">
        {people[client.emoji].char}
      </span>
    ) : null
  )
}

const StreamCountBar = () => {
  const count = useStore(s => s.streamcount)

  return count === 0 ? null : (
    <Grid
      sx={{
        display: ['none', 'grid'],
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        gridGap: 2,
        gridTemplateColumns: 'max-content 1fr',
        px: 3,
        py: 2,
        borderRadius: '1.5em',
        alignItems: 'flex-start',
        svg: {
          height: '1em'
        }
      }}
    >
      <Grid
        sx={{
          gridGap: 2,
          alignItems: 'center',
          gridTemplateColumns: 'min-content max-content'
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="black"
          viewBox="0 0 33 21"
          sx={{ mt: '-0.15em' }}
        >
          <path d="M16.563 0C7.303 0 .818 10.188.818 10.188S5.54 20.377 16.563 20.377c11.114 0 15.746-10.188 15.746-10.188S25.919 0 16.563 0zm0 16.671a6.442 6.442 0 01-6.482-6.482 6.442 6.442 0 016.482-6.483 6.442 6.442 0 016.483 6.482 6.442 6.442 0 01-6.483 6.483z" />
          <path d="M19.342 10.188a2.778 2.778 0 11-5.557 0 2.778 2.778 0 115.557 0z" />
        </svg>
        <div>
          {count > 1 ? `${count} people tuned in` : `${count} person tuned in`}
        </div>
      </Grid>
      <Flex
        sx={{
          filter: 'grayscale(1)',
          mixBlendMode: 'multiply',
          flexWrap: 'wrap'
        }}
      >
        <Emojis />
      </Flex>
    </Grid>
  )
}

export default StreamCountBar
