/** @jsx jsx */
import React from 'react'
import { Box, Text, Grid, jsx, Styled } from 'theme-ui'
import shallow from 'zustand/shallow'

import AbsolutLogo from '@components/AbsolutLogo'
import KornuitLogo from '@components/KornuitLogo'
import { useStore } from '@components/api'
import Chat from '@components/Chat'

const ExternalLink = (props) => (
  <Styled.a {...props} target="_blank" rel="noopener noreferrer" />
)

const SocialLinks = ({ test }: { test?: boolean }) => {
  const { live, rerun, socialLinks, showLinksOffline } = useStore(
    ({ live, rerun, socialLinks, showLinksOffline }) => ({
      live,
      rerun,
      socialLinks,
      showLinksOffline,
    }),
    shallow
  )

  if (!showLinksOffline && !test && !live && !rerun)
    return (
      <Text>
        We’re currently offline, but we’re streaming daily! Follow us on{' '}
        <ExternalLink href="https://www.instagram.com/radioradio.radio/">
          Instagram
        </ExternalLink>{' '}
        for details.
      </Text>
    )

  const safeLinks = socialLinks || []

  return (
    <Grid>
      {safeLinks.map((dj, index) => (
        <Box key={`dj-${index}`}>
          <Text>{dj.name}</Text>
          {dj.links.map(({ name, url }, linkIndex) => (
            <Text key={`link-${index}-${linkIndex}`}>
              → <ExternalLink href={url}>{name}</ExternalLink>
            </Text>
          ))}
        </Box>
      ))}
    </Grid>
  )
}

const Footer = ({ test }: { test?: boolean }) => {
  return (
    <Grid
      sx={{
        minHeight: '10vh',
        gridGap: 0,
        borderTop: '1px solid black',
        gridTemplateColumns: ['100%', '50% 50%', '1fr 1fr 1fr'],
        gridTemplateAreas: [
          `'chat' 'event' 'agenda' 'info' 'footer'`,
          `'event info' 'agenda agenda' 'footer footer'`,
          `'event agenda info' 'footer footer footer'`,
        ],
      }}
    >
      <div
        sx={{
          display: ['block', 'none'],
          position: 'relative',
          gridArea: 'chat',
          borderBottom: '1px solid black',
        }}
      >
        <Chat />
      </div>
      <Box
        p={[3, 4]}
        sx={{
          gridArea: 'event',
          borderRight: ['none', '1px solid black', 'none'],
        }}
      >
        <SocialLinks test={test} />
      </Box>
      <Box
        p={[3, 4]}
        sx={{
          gridArea: 'agenda',
          borderRight: ['none', 'none', '1px solid black'],
          borderLeft: ['none', 'none', '1px solid black'],
          borderTop: ['1px solid black', '1px solid black', 'none'],
          borderBottom: ['1px solid black', 'none', 'none'],
        }}
      >
        <Text>
          Welcome to the Radio Radio 2020 ADE stream featuring Clone, Bureau
          Punt &amp; Safe Trip.
        </Text>
      </Box>
      <Box
        p={[3, 4]}
        sx={{
          gridArea: 'info',
        }}
      >
        <Text>
          →{' '}
          <ExternalLink href="https://www.facebook.com/radioradioradioradioradio/">
            Facebook
          </ExternalLink>
          <br />→{' '}
          <ExternalLink href="https://www.instagram.com/radioradio.radio/">
            Instagram
          </ExternalLink>
          <br />→{' '}
          <ExternalLink href="https://www.mixcloud.com/radioradioradioradio/">
            Mixcloud
          </ExternalLink>
          <br />→{' '}
          <ExternalLink href="https://open.spotify.com/playlist/1hgZd0Rtn01QARvcsTzj3t?si=LN8wJHFjT02cc6_8D45SHw">
            Spotify
          </ExternalLink>
        </Text>
      </Box>
      {/* <Grid
        sx={{
          p: 3,
          gridTemplateColumns: 'max-content max-content max-content',
          gridArea: 'footer',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
          gridColumnGap: 2,
          gridRowGap: 0,
          borderTop: '1px solid black'
        }}
      >
        <Text>Partners</Text>
        <KornuitLogo sx={{ height: '1.4em' }} />
        <AbsolutLogo sx={{ height: '1.2em' }} />
      </Grid> */}
    </Grid>
  )
}

export default Footer
