import { BufferGeometry, Float32BufferAttribute, Vector3 } from 'three'

var CustomBoxLineGeometry = function(
  startFrame,
  endFrame,
  widthSegments,
  heightSegments,
  depthSegments
) {
  BufferGeometry.call(this)

  var depth = endFrame.topLeft.z - startFrame.topLeft.z
  var endWidth = endFrame.topRight.x - endFrame.topLeft.x
  var startWidth = startFrame.topRight.x - startFrame.topLeft.x

  widthSegments = Math.floor(widthSegments) || 1
  heightSegments = Math.floor(heightSegments) || 1
  depthSegments = Math.floor(depthSegments) || 1

  var startSegmentWidth = startWidth / widthSegments
  var segmentDepth = depth / depthSegments
  var endSegmentWidth = endWidth / widthSegments

  var vertices = []

  var eX = endFrame.topLeft.x
  var x = startFrame.topLeft.x
  var z = startFrame.topLeft.z

  for (var i = 0; i <= widthSegments; i++) {
    if (i === 1 || i === 5) {
      eX += endSegmentWidth
      x += startSegmentWidth
      continue
    }
    vertices.push(
      eX,
      endFrame.topLeft.y,
      endFrame.topLeft.z,
      x,
      startFrame.topLeft.y,
      startFrame.topLeft.z
    )
    vertices.push(
      x,
      startFrame.bottomLeft.y,
      startFrame.topLeft.z,
      eX,
      endFrame.bottomLeft.y,
      endFrame.topLeft.z
    )

    eX += endSegmentWidth
    x += startSegmentWidth
  }
  vertices.push(
    endFrame.topLeft.x,
    endFrame.topLeft.y,
    endFrame.topLeft.z,
    endFrame.bottomLeft.x,
    endFrame.bottomLeft.y,
    endFrame.bottomLeft.z
  )
  vertices.push(
    endFrame.topRight.x,
    endFrame.topRight.y,
    endFrame.topRight.z,
    endFrame.bottomRight.x,
    endFrame.bottomRight.y,
    endFrame.bottomRight.z
  )

  for (var i = 0; i <= depthSegments; i++) {
    const topLeft = new Vector3().lerpVectors(
      startFrame.topLeft,
      endFrame.topLeft,
      i / depthSegments
    )
    const topRight = new Vector3().lerpVectors(
      startFrame.topRight,
      endFrame.topRight,
      i / depthSegments
    )
    const bottomLeft = new Vector3().lerpVectors(
      startFrame.bottomLeft,
      endFrame.bottomLeft,
      i / depthSegments
    )
    const bottomRight = new Vector3().lerpVectors(
      startFrame.bottomRight,
      endFrame.bottomRight,
      i / depthSegments
    )
    vertices.push(topLeft.x, topLeft.y, z, topRight.x, topRight.y, z)
    if (i !== 0) {
      vertices.push(
        bottomLeft.x,
        bottomLeft.y,
        z,
        bottomRight.x,
        bottomRight.y,
        z
      )
    }

    z += segmentDepth
  }

  this.setAttribute('position', new Float32BufferAttribute(vertices, 3))
}

CustomBoxLineGeometry.prototype = Object.create(BufferGeometry.prototype)
CustomBoxLineGeometry.prototype.constructor = CustomBoxLineGeometry

export { CustomBoxLineGeometry }
