/** @jsx jsx */
import React from 'react'
import { Grid, jsx, Text, Box } from 'theme-ui'
import { RouteComponentProps } from '@reach/router'
import { useMatch } from '@reach/router'
import { navigate } from 'gatsby'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
import { Button } from '@components/Button'
import World from '@components/World'
import { useStore } from '@components/api'
import { like } from '@contexts/Socket'
import DonateModal from '@components/DonateModal'
import ThankYouModal from '@components/ThankYouModal'
import { Header } from '@components/Header'
import Footer from '@components/Footer'
import StreamCountBar from '@components/StreamCountBar'
import Chat from '@components/Chat'

const Home = ({
  hideFooter = false,
  test = false,
}: RouteComponentProps & { hideFooter?: boolean; test?: boolean }) => {
  const showThanks = useMatch('/thank-you')
  const showDonations = useStore((s) => s.showDonations)
  const [showDialog, setShowDialog] = React.useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)
  const closeThankyou = () => {
    navigate('/', { replace: true })
  }

  const triggerHeart = () => {
    like()
  }

  return (
    <Layout>
      <SEO title="Radio Radio Live" />
      <Header test={test} />

      <World hideFooter={hideFooter} test={test} showDonations={showDonations}>
        <React.Fragment>
          <Grid
            sx={{
              position: 'absolute',
              p: [3, 0],
              width: ['100%', 'auto'],
              bottom: [0, '5vh'],
              left: [0, '50%'],
              transform: ['none', 'translateX(-50%)'],
              gridColumnGap: 3,
              alignItems: 'center',
              justifyContent: 'center',
              gridTemplateColumns: 'auto auto',
            }}
          >
            <Button sx={{ fontSize: 4, m: 0 }} onClick={open}>
              Donate
            </Button>
            <Button
              sx={{
                fontSize: 4,
                minWidth: 0,
                m: 0,
                height: '100%',
                px: '0.625em',
              }}
              onClick={triggerHeart}
            >
              <div
                sx={{
                  height: '1.5em',
                  width: '1.5em',
                  backgroundImage: `url(${require('@media/heart.png')})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                }}
              />
            </Button>
          </Grid>
          <div
            sx={{
              display: ['none', 'block'],
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%',
              width: '20vw',
            }}
          >
            <Chat />
          </div>
          <StreamCountBar />
        </React.Fragment>
      </World>

      {!hideFooter && <Footer test={test} />}

      <ThankYouModal showDialog={!!showThanks} onDismiss={closeThankyou} />
      <DonateModal showDialog={!showThanks && showDialog} onDismiss={close} />
    </Layout>
  )
}

export default Home
