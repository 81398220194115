/** @jsx jsx */
import React from 'react'
import { jsx, Spinner, Grid, Text } from 'theme-ui'
import ky from 'ky'

import { useField } from 'formik'

const ContributionButtons = () => {
  const [error, setError] = React.useState<string>()
  const [products, setProducts] = React.useState([])
  const [, meta, helpers] = useField('Id')

  const { value } = meta
  const { setValue } = helpers

  React.useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await ky
          .get('https://rara-donate.herokuapp.com/api/products')
          .json()
        setProducts(response.data.products)
      } catch (e) {
        setError('Could not get donations.')
      }
    }
    getProducts()
  }, [])

  if (error) return <Text>{error}</Text>
  if (products.length === 0) {
    return (
      <Grid
        gap={0}
        sx={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner sx={{ height: '2em' }} />
      </Grid>
    )
  }
  return (
    <Grid
      gap={0}
      sx={{
        gridTemplateAreas: `'button' 'button' 'button' 'button'`
      }}
    >
      {products.map((product, i) => {
        const isSelected = product.Id === value
        return (
          <div
            key={`product-${i}`}
            role="button"
            onKeyDown={ev => {
              if (
                ev.key === ' ' ||
                ev.key === 'Enter' ||
                ev.key === 'Spacebar'
              ) {
                setValue(product.Id)
              }
            }}
            onClick={() => setValue(product.Id)}
            aria-pressed={isSelected}
            tabIndex="0"
            sx={{
              appearance: 'none',
              fontFamily: 'inherit',
              display: 'grid',
              gridRowGap: 1,
              gridColumnGap: 0,
              alignItems: 'center',
              justifyContent: 'center',
              gridTemplateRows: 'max-content max-content',
              minWidth: '6.25em',
              border: 'none',
              borderRadius: 0,
              textAlign: 'center',
              p: [3, 4],
              m: 0,
              fontSize: '1rem',
              background: isSelected ? 'black' : 'none',
              color: isSelected ? 'var(--main-color)' : 'black',
              borderBottom: '1px solid black',
              borderRight: '1px solid black',
              borderLeft: '1px solid black',
              '&:nth-of-type(1)': {
                borderTop: '1px solid black'
              },
              '@media not all and (hover: none)': {
                '&:hover': {
                  cursor: 'pointer',
                  color: 'var(--main-color)',
                  bg: 'black'
                }
              },
              '&:focus': {
                outline: 'none',
                color: 'var(--main-color)',
                bg: 'black'
              }
            }}
            type="button"
          >
            <Text>{product.Title}</Text>
            <Text sx={{ fontSize: 4 }}>€ {parseFloat(product.Price)}</Text>
          </div>
        )
      })}
    </Grid>
  )
}

export default ContributionButtons
