import React from 'react'
import { useLoader, useFrame, useThree } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { Color, Object3D } from 'three'

import { api, useStore, MAX_HEARTS } from '@components/api'

const Hearts = ({ depth }) => {
  const ref = React.useRef()
  const { nodes, materials } = useLoader(
    GLTFLoader,
    '/heart/heart.gltf',
    loader => {
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath('/draco-gltf/')
      loader.setDRACOLoader(dracoLoader)
    }
  )
  const activeHearts = React.useRef(api.getState().hearts)
  const clearHeart = useStore(s => s.clearHeart)
  const { viewport } = useThree()

  const halfViewWidth = viewport.width * 0.5
  const qViewWidth = halfViewWidth * 0.5

  const dummy = React.useMemo(() => new Object3D(), [])
  const objects = React.useMemo(() => {
    return new Array(MAX_HEARTS).fill(0).map(() => ({
      position: [
        qViewWidth - Math.random() * halfViewWidth,
        8,
        depth - Math.random() * depth
      ],
      rotation: [
        Math.sin(Math.random()) * Math.PI,
        Math.sin(Math.random()) * Math.PI,
        Math.cos(Math.random()) * Math.PI
      ],
      factor: 0.15 + Math.random() * 0.85,
      scale: Math.random() * 0.003 + 0.003
    }))
  }, [])

  React.useEffect(() => {
    materials['Heart Red'].color = new Color('rgb(248,84,78)')
  }, [])

  React.useEffect(
    () =>
      api.subscribe(
        hearts => (activeHearts.current = hearts),
        state => state.hearts
      ),
    []
  )

  useFrame((state, delta) => {
    activeHearts.current.forEach(id => {
      const obj = objects[id]
      obj.position[1] -= obj.factor / 5
      if (obj.position[1] < -9) {
        obj.position = [
          qViewWidth - Math.random() * halfViewWidth,
          8,
          depth - Math.random() * depth // 1 to 3
        ]
        clearHeart(id)
      } else {
        obj.rotation[0] = obj.rotation[0] + delta * obj.factor
        obj.rotation[1] = obj.rotation[1] + delta * obj.factor
        obj.rotation[2] = obj.rotation[2] + delta * obj.factor
      }
      dummy.position.set(obj.position[0], obj.position[1], obj.position[2])
      dummy.rotation.set(obj.rotation[0], obj.rotation[1], obj.rotation[2])
      dummy.scale.set(obj.scale, obj.scale, obj.scale)
      dummy.updateMatrix()
      ref.current.setMatrixAt(id, dummy.matrix)
    })
    ref.current.instanceMatrix.needsUpdate = true
  })

  return (
    <instancedMesh
      ref={ref}
      args={[nodes['node-0'].geometry, materials['Heart Red'], MAX_HEARTS]}
    />
  )
}

export default Hearts
