import React from 'react'
import { useFrame } from 'react-three-fiber'
import {
  CanvasTexture,
  Vector2,
  DoubleSide,
  sRGBEncoding,
  RepeatWrapping
} from 'three'

function fitTextOnCanvas(text, fontface, width, ctx) {
  var size = measureTextBinaryMethod(text, fontface, 0, 600, width, ctx)
  return size
}

function measureTextBinaryMethod(text, fontface, min, max, desiredWidth, ctx) {
  if (max - min < 1) {
    return min
  }
  var test = min + (max - min) / 2 //Find half interval
  ctx.font = test + 'px ' + fontface
  var measureTest = ctx.measureText(text).width
  var found
  if (measureTest > desiredWidth) {
    var found = measureTextBinaryMethod(
      text,
      fontface,
      min,
      test,
      desiredWidth,
      ctx
    )
  } else {
    var found = measureTextBinaryMethod(
      text,
      fontface,
      test,
      max,
      desiredWidth,
      ctx
    )
  }
  return found
}

const Blob = ({ donation, ...rest }) => {
  const ref = React.useRef(null)
  const r = 0.25

  const nameTexture = React.useMemo(() => {
    const canvas = document.createElement('canvas')
    const width = 256
    const height = width
    canvas.width = width
    canvas.height = width
    const ctx = canvas.getContext('2d')
    // ctx.fillStyle = '#ffffff'
    // ctx.fillRect(0, 0, width, height)
    const text = `${donation.Name} — `
    ctx.font = `${fitTextOnCanvas(
      text,
      'radioradio, Helvetica, Arial',
      width,
      ctx
    )}px radioradio, Helvetica, Arial`
    ctx.fillStyle = 'black'
    ctx.textAlign = 'center'
    ctx.fillText(text, width * 0.5, height * 0.5)
    const texture = new CanvasTexture(canvas)
    texture.anisotropy = 8
    texture.encoding = sRGBEncoding
    texture.wrapS = RepeatWrapping
    texture.wrapT = RepeatWrapping
    texture.repeat = new Vector2(2, 1)
    return texture
  }, [donation.Name])

  useFrame(() => {
    if (!ref.current) return
    ref.current.rotation.y += 0.01
  })

  const scale = donation.Name < 5 ? 0.8 : donation.Name.length > 8 ? 1.65 : 1.15

  return (
    <group {...rest}>
      <mesh ref={ref} scale={[scale, scale, scale]}>
        <cylinderBufferGeometry
          attach="geometry"
          args={[r, r, 1, 32, 1, true]}
        />
        <meshBasicMaterial
          transparent
          attach="material"
          map={nameTexture}
          side={DoubleSide}
          alphaTest={0.05}
        />
      </mesh>
    </group>
  )
}

export default Blob
