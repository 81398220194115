/** @jsx jsx */
import { Text, Grid, jsx } from 'theme-ui'

import { CloseButton } from '@components/Button'
import Modal from '@components/Modal'

const ThankYouModal = ({
  showDialog,
  onDismiss,
}: {
  showDialog: boolean
  onDismiss: () => void
}) => (
  <Modal showDialog={showDialog} onDismiss={onDismiss}>
    <Grid
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        my: 4,
      }}
    >
      <Text>Thanks for your donation!</Text>
      <CloseButton onClick={onDismiss} />
    </Grid>
  </Modal>
)

export default ThankYouModal
