/** @jsx jsx */
import React, { Suspense } from 'react'
import { jsx } from 'theme-ui'
import { Canvas, useFrame, useThree, extend } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { MathUtils, Vector3, DoubleSide } from 'three'
import ky from 'ky'

import { useStore } from '@components/api'
import { CustomBoxLineGeometry } from '@components/CustomBoxLineGeometry'
import Hearts from '@components/Heart'
import Cylinder from '@components/Cylinder'
import Stream from '@components/Stream'
// import Effects from '@components/Effects'

extend({
  OrbitControls,
  CustomBoxLineGeometry,
})

function Controls() {
  const controls = React.useRef()
  const { camera, gl } = useThree()
  useFrame(() => controls.current.update())
  return (
    <orbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      enableDamping
      dampingFactor={0.1}
      rotateSpeed={0.5}
    />
  )
}

const TestPoint = ({ position }) => (
  <mesh position={position}>
    <sphereBufferGeometry args={[0.1, 24, 24]} attach="geometry" />
    <meshBasicMaterial attach="material" color="red" />
  </mesh>
)

const Frame = ({ points }) => (
  <React.Fragment>
    <mesh position={[0, 0, points.topLeft.z]}>
      <planeBufferGeometry
        args={[
          points.topRight.x - points.topLeft.x,
          points.topRight.y - points.bottomRight.y,
        ]}
        attach="geometry"
      />
      <meshBasicMaterial
        attach="material"
        transparent
        opacity={0.15}
        side={DoubleSide}
        color="blue"
      />
    </mesh>
    <TestPoint position={points.topLeft} />
    <TestPoint position={points.topRight} />
    <TestPoint position={points.bottomLeft} />
    <TestPoint position={points.bottomRight} />
  </React.Fragment>
)

const Donations = ({ depth }) => {
  const group = React.useRef()

  const donateCount = useStore((s) => s.donateCount)
  const [donations, setDonations] = React.useState([])
  const { viewport } = useThree()

  const halfViewWidth = viewport.width * 0.5
  const qViewWidth = halfViewWidth * 0.5 - 0.25

  React.useEffect(() => {
    const getDonations = async () => {
      try {
        const response = await ky
          .get('https://rara-donate.herokuapp.com/api/donated')
          .json()
        setDonations(response.data.Donations)
      } catch (e) {
        console.log('Could not get donations', e)
      }
    }
    getDonations()
  }, [donateCount])

  const blobs = React.useMemo(() => {
    const v = 0.01
    return donations.map((donation) => {
      // const scale = Math.random() * 0.7 + 0.5
      return {
        donation,
        userData: {
          velocity: new Vector3(
            Math.random() * v - v / 2,
            Math.random() * v - v / 2,
            Math.random() * v - v / 2
          ),
        },
        position: [
          qViewWidth - Math.random() * halfViewWidth,
          3 - Math.random() * 6,
          depth - Math.random() * depth,
        ],
        rotation: [
          Math.random() * 2 * Math.PI,
          Math.random() * 2 * Math.PI,
          Math.random() * 2 * Math.PI,
        ],
      }
    })
  }, [donations])

  useFrame((state, delta) => {
    const multiplier = 50 * delta
    for (let i = 0; i < group.current.children.length; i++) {
      let cube = group.current.children[i]
      cube.userData.velocity.multiplyScalar(1 - 0.001 * delta)
      cube.position.add(cube.userData.velocity)
      if (cube.position.x < -qViewWidth || cube.position.x > qViewWidth) {
        cube.position.x = MathUtils.clamp(
          cube.position.x,
          -qViewWidth,
          qViewWidth
        )
        cube.userData.velocity.x = -cube.userData.velocity.x
      }
      if (cube.position.y < -2.75 || cube.position.y > 2.75) {
        cube.position.y = MathUtils.clamp(cube.position.y, -2.75, 2.75)
        cube.userData.velocity.y = -cube.userData.velocity.y
      }
      if (cube.position.z < 0 || cube.position.z > depth) {
        cube.position.z = MathUtils.clamp(cube.position.z, 0, depth)
        cube.userData.velocity.z = -cube.userData.velocity.z
      }
      cube.rotation.x += cube.userData.velocity.x * multiplier
      cube.rotation.y += cube.userData.velocity.y * multiplier
      cube.rotation.z += cube.userData.velocity.z * multiplier
    }
  })

  return (
    <group ref={group}>
      {blobs.map((props, i) => (
        <Cylinder key={props.donation.Hash} {...props} />
      ))}
    </group>
  )
}

const Room = () => {
  const { viewport, size } = useThree()

  const halfViewWidth = viewport.width * 0.5
  const qViewWidth = halfViewWidth * 0.5
  const halfRatio = size.width < 40 * 16 ? 0.45 : 0.3
  const halfVideoSize = {
    width: viewport.width * halfRatio,
    height: (9 / 16) * halfRatio * viewport.width,
  }
  const points = React.useMemo(() => {
    return {
      topLeft: new Vector3(-halfVideoSize.width, halfVideoSize.height, 0),
      topRight: new Vector3(halfVideoSize.width, halfVideoSize.height, 0),
      bottomLeft: new Vector3(-halfVideoSize.width, -halfVideoSize.height, 0),
      bottomRight: new Vector3(halfVideoSize.width, -halfVideoSize.height, 0),
    }
  }, [halfVideoSize])
  const framePoints = React.useMemo(() => {
    return {
      topLeft: new Vector3(-qViewWidth, viewport.height * 0.25, 3),
      topRight: new Vector3(qViewWidth, viewport.height * 0.25, 3),
      bottomLeft: new Vector3(-qViewWidth, -viewport.height * 0.25, 3),
      bottomRight: new Vector3(qViewWidth, -viewport.height * 0.25, 3),
    }
  }, [qViewWidth, viewport.height])

  return (
    <React.Fragment>
      <lineSegments position={[0, 0, 0]}>
        <customBoxLineGeometry
          attach="geometry"
          args={[framePoints, points, 6, 0, 3]}
        />
        <lineBasicMaterial attach="material" color="#000000" />
      </lineSegments>
      {/* <Frame points={points} />
      <Frame points={framePoints} /> */}
    </React.Fragment>
  )
}

const World = ({
  children,
  hideFooter,
  showDonations = true,
  test,
}: {
  hideFooter?: boolean
  test?: boolean
  showDonations?: boolean
}) => {
  const debug = false
  const depth = 3

  return (
    <div
      sx={{
        position: 'relative',
        width: '100%',
        height: hideFooter ? 'calc(100vh - 50px)' : ['66vh', '85vh'],
      }}
    >
      <Stream hideFooter={hideFooter} test={test} />

      <Canvas
        sx={{ pointerEvents: debug ? 'all' : 'none' }}
        pixelRatio={Math.min(
          1.3,
          typeof window !== `undefined` ? window.devicePixelRatio : 1
        )}
        camera={{ fov: 90, position: [0, 0, 6] }}
      >
        <ambientLight />
        {/* <hemisphereLight args={[0x606060, 0x404040]} /> */}
        <directionalLight args={[0xffffff]} position={[1, 3, 3]} />
        <Room />
        {showDonations && <Donations depth={depth} />}
        <Suspense fallback={null}>
          <Hearts depth={depth} />
        </Suspense>
        {/* <Effects /> */}
        {debug && <Controls />}
      </Canvas>
      {children}
    </div>
  )
}

export default World
